<template>
  <div class="StageInit">
    <img
      :src="$config.init.mainBg"
      aria-hidden="true"
      draggable="false"
      class="main--bg"
    />
    <div class="content">
      <div class="content_wrap">
        <span>點擊上方</span>
        <span>案件還原</span>
        <span>開始作答</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from '@vue/composition-api'

export default defineComponent({
  props: {
    injectKey: {
      type: [String,Symbol],
      required: true,
    }
  },
  setup(props) {
    const $config = inject(props.injectKey)

    return {
      $config
    }
  },
})
</script>


<style lang="scss">
.StageInit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  > .main--bg {
    max-width: 375px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: relative;
    &_wrap {
      color: rgb(186, 176, 176);
      font-size: 30px;
      font-weight: 600;
      display: flex;
      flex-direction: column;

      span:nth-child(1) {
        margin-left: -30px;
      }
      span:nth-child(3) {
        margin-left: 30px;
      }
    }
  }
}
</style>